import styled, { css } from 'styled-components';

const BoldFont = 'Open-Sans-Bold';
// const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const TopbarGrid = styled.div`
  width: 100%;
  background-color: ${({backgroundColor})=>backgroundColor};
  float: left;
  color: #56d4dd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 10px 0;
  // margin-bottom: 5px;
  @media (max-width: 500px) {
    // padding: 10px 10px;
  }
`;

const Logo = styled.div`
  height: ${({height}) => !height ? '' : '100%'};
  padding-left: 25px;
  width: 200px;
  position: relative;
  cursor: pointer;
  height: ${({height}) => !height ? '' : '100%'};
  display: inline-block;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div.imageContainer {
    flex-basis: 80%;
    display: flex;
    justify-content: flex-start;
  }
  > div.imageContainer img {
    height: ${({width}) => width ? '60px' : '40px'};
    width: auto;
  }

  @media (max-width: 500px) {
    padding-left: 0px;
    > img:first-child {
      margin-left: 15%;
    }
  }
  @media (max-width: 380px) {
    > img:first-child  {
      margin-left: 8%;
      max-width: 96px;
    }
  }
  @media (max-width: 350px) {
    padding-right: 5px;
    padding-left: 5px;
    > img:first-child  {
      margin-left: 0px;
      max-width: 96px;
    }
  }
  @media(max-width: 337px) {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  ${({hide}) => hide === 1 && css`
    width: 70px;
    > img {
      float: none;
      padding-right: 10px;
      margin-top: 10px;
      width: 30px;
      height: 25px;
      border: 10px solid black;
      margin-left: 10px;
    }
  `}
`;
const IconsWrapper = styled.div`
  float: right;
  display: flex;
  padding: 10px 0;
  margin-right: 20px;
  height: 100%;
  align-items: center;  
  @media (max-width: 500px) {
    margin-right: 0;
  }
`;
const IconContainer = styled.div`
  //display: flex;
  align-items: center;
  font-family: ${BoldFont};
  // padding: ${({padding}) => (padding) ? padding :'0 20px;'};
  padding: 10px 25px;
  cursor: pointer;
  position: relative;
  //position: fixed;
  > .notification-bell:hover g path {
        fill: ${({color}) => color || "#3d4d67"};
   }
  @media (max-width: 500px) {
    padding: 10px 6px;
  }

   > img {
    height: 24px;
    width: 24px;
   }
  span{
    position: absolute;
    width: 9px;
    height: 9px;
    background: #fd7175;
    border-radius: 50%;
    left: 30px;
    top: -1px;
  }
  @media (max-width: 500px){
    span{
      top: 16px;
      left: 19px;
    }
  }
  ${({ active }) => active && css`
    > svg {
      fill: ${({color}) => color || "#3d4d67"};
    }
  `}
`;
const NameContainer = styled.div`
  width: 100%;
  background-color: ${({backgroundColor})=>backgroundColor};
  color: rgba(0,47,71,1);
  font-family: ${MediumFont};
  > p {
    margin-bottom: 0px;
    padding: 5px 10px 5px 5px;

    > span {
      font-weight: bold;
    }

  }
`;
const ProfileImg = styled.div`
padding: 10px;
cursor: pointer;
> img {
  width: 42px;
  border-radius: 4px;height: 42px;
}
  > ul {
    width: 170px;
    list-style: none;
    display: none;
    position: absolute;
    top: 65px;
    right: 10px;
    background-color: white;
    font-family: ${BoldFont};
    padding: 0;
    z-index: 1;
    box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
    
    @media (max-width: 500px) {
      display: none;
    }

    > li {
      text-align: center;
      padding: 5px 0;
      > a {
        width: 100%;
        display: block;
        padding: 5px 30px;
        color: #67676A;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
  &:hover > ul {
    display: block;
  }

  ul > li:hover {
    color: white;
    background-color: ${({color}) => color || "#3d4d67"};
  }
`;
const Languagecontainer = styled.div`
display: table-cell;
vertical-align: middle;
display: inline;
  > ul {
    width: 170px;
    list-style: none;
    display: none;
    position: absolute;
    top: 40px;
    right: 80px;
    background-color: white;
    font-family: ${BoldFont};
    padding: 0;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
    
    @media (max-width: 500px) {
      display: none;
    }

    > li {
      text-align: center;
      padding: 5px 0;
      color: #67676A;
      > a {
        width: 100%;
        display: block;
        padding: 5px 25px;
        color: #67676A;
        cursor: pointer;
        text-transform: capitalize;
        &:hover {
          text-decoration: none;
          color: white;
        }
        > img {
          width: 19px;
          height: 19px;
          float: left;
          margin-right: 5px;
        }
      }
    }
  }
  &:hover > ul {
    display: block;
  }
  ul > li:hover {
    color: white;
    background-color: ${({color}) => color || "#3d4d67"};
  }
`;
const SvgIconContainer = styled.div`
display: flex;
align-items: center;
font-family: ${BoldFont};
padding: ${({padding}) => (padding) ? padding :'0 20px'};
cursor: pointer;
position: relative;

&:hover {
  >svg {
    fill: ${({color}) => color || "#3d4d67"};
  }
}

@media (max-width: 500px) {
  padding: 10px 6px;
}

> svg {
  margin-top: 3px;
  // fill: rgba(105,194,255);
  fill: #9c9c9c;
  position: relative;
  pointer-events: none;
}
@media (max-width: 500px){
  span{
    top: 16px;
    left: 19px;
  }
}
${({ active }) => active && css`
  > svg {
    fill: ${({color}) => color || "#3d4d67"};
  }
`}
`;
const ArrowImg = styled.div`
float: right;
padding-right: 40px;
width: 23px;
height: 17px;

margin-top: 5px;
  @media (max-width: 350px) {
    padding-right: 0px;
    width: 12px;
  }
`;
const CollapsArrow = styled.div`
float: right;
margin-top: 10px;
width: 100%;
padding-left: 10px;
`;

const ViewButton = styled.div`
border-radius: 5px;
border: ${({color}) => `1px solid ${color}`};
background:rgba(0, 92, 135, 0.05);
height: 40px;
color:  ${({color}) => color || "#005C87"};;
font-family: Rubik-Medium;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
min-width:150px;
padding: 5px;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
:hover{
  color:white;
  background:${({background}) => background || "rgba(0, 92, 135, 0.05)"};

}
`;

const PortalGrid = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  // max-width: 1600px;
  margin: 0 auto;

  > div:nth-child(2) {
    position: fixed;
    top: 0;
    z-index: 1001;
  }

  > div:nth-child(3) {
    top: 70px;
    position: fixed;
    z-index: 99;
    background: white;
    @media (max-width: 700px) {
      background: unset;
      z-index: 3;
    }
  }

  > div:nth-child(4) {
    padding-top: 70px;
  }

  .Toastify__toast-body {
    margin-right: 20px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  float: left;
  position: relative;
  min-height: 800px;
`;

const ContentStyle = styled.div`
  width: calc(100% - ${({ hideContent }) => (hideContent ? "80px" : "200px")}) !important;
  margin-left: ${({ hideContent }) => (hideContent ? "80px" : "200px")} !important;
  float: left !important;
  background-color: #f7f5eb !important;
  min-height: 800px !important;
  @media (max-width: 950px) {
    margin-left: 0;
    width: 100%;
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: 5px;
`;


export { TopbarGrid, IconsWrapper, IconContainer, NameContainer, ProfileImg, Languagecontainer, SvgIconContainer, Logo, ArrowImg,
  CollapsArrow, ViewButton, PortalGrid, ContentContainer, ContentStyle, StyledButton
};
